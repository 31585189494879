@charset "UTF-8";
/**
 * Utility classes for spacing:
 * A wide range of shorthand margin and padding utility classes to modify an
 * an element’s appearance.
 * Prefix understanding: u means utility which is a prefix for root namespace,
 * s means spacing which is a prefix for sub namespace, m means margin,
 * p means padding. e.g. `u-s-m-t-22` means apply top margin 22 pixels.
 */
/* Margin with value `auto`. */
.u-s-m-t-auto {
  margin-top: auto; }

.u-s-m-r-auto {
  margin-right: auto; }

.u-s-m-b-auto {
  margin-bottom: auto; }

.u-s-m-l-auto {
  margin-left: auto; }

.u-s-m-x-auto {
  margin: auto 0; }

.u-s-m-y-auto {
  margin: 0 auto; }

.u-s-m-xy-auto {
  margin: auto; }

/* Padding with value `auto`. */
.u-s-p-t-auto {
  padding-top: auto; }

.u-s-p-r-auto {
  padding-right: auto; }

.u-s-p-b-auto {
  padding-bottom: auto; }

.u-s-p-l-auto {
  padding-left: auto; }

.u-s-p-x-auto {
  padding: auto 0; }

.u-s-p-y-auto {
  padding: 0 auto; }

.u-s-p-xy-auto {
  padding: auto; }

/* Margin with value `0`. */
.u-s-m-t-0 {
  margin-top: 0; }

.u-s-m-r-0 {
  margin-right: 0; }

.u-s-m-b-0 {
  margin-bottom: 0; }

.u-s-m-l-0 {
  margin-left: 0; }

.u-s-m-x-0 {
  margin: 0 0; }

.u-s-m-y-0 {
  margin: 0 0; }

.u-s-m-xy-0 {
  margin: 0; }

/* Padding with value `0`. */
.u-s-p-t-0 {
  padding-top: 0; }

.u-s-p-r-0 {
  padding-right: 0; }

.u-s-p-b-0 {
  padding-bottom: 0; }

.u-s-p-l-0 {
  padding-left: 0; }

.u-s-p-x-0 {
  padding: 0 0; }

.u-s-p-y-0 {
  padding: 0 0; }

.u-s-p-xy-0 {
  padding: 0; }

/* Margin with value `1`. */
.u-s-m-t-1 {
  margin-top: 1px; }

.u-s-m-r-1 {
  margin-right: 1px; }

.u-s-m-b-1 {
  margin-bottom: 1px; }

.u-s-m-l-1 {
  margin-left: 1px; }

.u-s-m-x-1 {
  margin: 0 1px; }

.u-s-m-y-1 {
  margin: 1px 0; }

.u-s-m-xy-1 {
  margin: 1px; }

/* Padding with value `1`. */
.u-s-p-t-1 {
  padding-top: 1px; }

.u-s-p-r-1 {
  padding-right: 1px; }

.u-s-p-b-1 {
  padding-bottom: 1px; }

.u-s-p-l-1 {
  padding-left: 1px; }

.u-s-p-x-1 {
  padding: 0 1px; }

.u-s-p-y-1 {
  padding: 1px 0; }

.u-s-p-xy-1 {
  padding: 1px; }

/* Margin with value `2`. */
.u-s-m-t-2 {
  margin-top: 2px; }

.u-s-m-r-2 {
  margin-right: 2px; }

.u-s-m-b-2 {
  margin-bottom: 2px; }

.u-s-m-l-2 {
  margin-left: 2px; }

.u-s-m-x-2 {
  margin: 0 2px; }

.u-s-m-y-2 {
  margin: 2px 0; }

.u-s-m-xy-2 {
  margin: 2px; }

/* Padding with value `2`. */
.u-s-p-t-2 {
  padding-top: 2px; }

.u-s-p-r-2 {
  padding-right: 2px; }

.u-s-p-b-2 {
  padding-bottom: 2px; }

.u-s-p-l-2 {
  padding-left: 2px; }

.u-s-p-x-2 {
  padding: 0 2px; }

.u-s-p-y-2 {
  padding: 2px 0; }

.u-s-p-xy-2 {
  padding: 2px; }

/* Margin with value `3`. */
.u-s-m-t-3 {
  margin-top: 3px; }

.u-s-m-r-3 {
  margin-right: 3px; }

.u-s-m-b-3 {
  margin-bottom: 3px; }

.u-s-m-l-3 {
  margin-left: 3px; }

.u-s-m-x-3 {
  margin: 0 3px; }

.u-s-m-y-3 {
  margin: 3px 0; }

.u-s-m-xy-3 {
  margin: 3px; }

/* Padding with value `3`. */
.u-s-p-t-3 {
  padding-top: 3px; }

.u-s-p-r-3 {
  padding-right: 3px; }

.u-s-p-b-3 {
  padding-bottom: 3px; }

.u-s-p-l-3 {
  padding-left: 3px; }

.u-s-p-x-3 {
  padding: 0 3px; }

.u-s-p-y-3 {
  padding: 3px 0; }

.u-s-p-xy-3 {
  padding: 3px; }

/* Margin with value `4`. */
.u-s-m-t-4 {
  margin-top: 4px; }

.u-s-m-r-4 {
  margin-right: 4px; }

.u-s-m-b-4 {
  margin-bottom: 4px; }

.u-s-m-l-4 {
  margin-left: 4px; }

.u-s-m-x-4 {
  margin: 0 4px; }

.u-s-m-y-4 {
  margin: 4px 0; }

.u-s-m-xy-4 {
  margin: 4px; }

/* Padding with value `4`. */
.u-s-p-t-4 {
  padding-top: 4px; }

.u-s-p-r-4 {
  padding-right: 4px; }

.u-s-p-b-4 {
  padding-bottom: 4px; }

.u-s-p-l-4 {
  padding-left: 4px; }

.u-s-p-x-4 {
  padding: 0 4px; }

.u-s-p-y-4 {
  padding: 4px 0; }

.u-s-p-xy-4 {
  padding: 4px; }

/* Margin with value `5`. */
.u-s-m-t-5 {
  margin-top: 5px; }

.u-s-m-r-5 {
  margin-right: 5px; }

.u-s-m-b-5 {
  margin-bottom: 5px; }

.u-s-m-l-5 {
  margin-left: 5px; }

.u-s-m-x-5 {
  margin: 0 5px; }

.u-s-m-y-5 {
  margin: 5px 0; }

.u-s-m-xy-5 {
  margin: 5px; }

/* Padding with value `5`. */
.u-s-p-t-5 {
  padding-top: 5px; }

.u-s-p-r-5 {
  padding-right: 5px; }

.u-s-p-b-5 {
  padding-bottom: 5px; }

.u-s-p-l-5 {
  padding-left: 5px; }

.u-s-p-x-5 {
  padding: 0 5px; }

.u-s-p-y-5 {
  padding: 5px 0; }

.u-s-p-xy-5 {
  padding: 5px; }

/* Margin with value `6`. */
.u-s-m-t-6 {
  margin-top: 6px; }

.u-s-m-r-6 {
  margin-right: 6px; }

.u-s-m-b-6 {
  margin-bottom: 6px; }

.u-s-m-l-6 {
  margin-left: 6px; }

.u-s-m-x-6 {
  margin: 0 6px; }

.u-s-m-y-6 {
  margin: 6px 0; }

.u-s-m-xy-6 {
  margin: 6px; }

/* Padding with value `6`. */
.u-s-p-t-6 {
  padding-top: 6px; }

.u-s-p-r-6 {
  padding-right: 6px; }

.u-s-p-b-6 {
  padding-bottom: 6px; }

.u-s-p-l-6 {
  padding-left: 6px; }

.u-s-p-x-6 {
  padding: 0 6px; }

.u-s-p-y-6 {
  padding: 6px 0; }

.u-s-p-xy-6 {
  padding: 6px; }

/* Margin with value `7`. */
.u-s-m-t-7 {
  margin-top: 7px; }

.u-s-m-r-7 {
  margin-right: 7px; }

.u-s-m-b-7 {
  margin-bottom: 7px; }

.u-s-m-l-7 {
  margin-left: 7px; }

.u-s-m-x-7 {
  margin: 0 7px; }

.u-s-m-y-7 {
  margin: 7px 0; }

.u-s-m-xy-7 {
  margin: 7px; }

/* Padding with value `7`. */
.u-s-p-t-7 {
  padding-top: 7px; }

.u-s-p-r-7 {
  padding-right: 7px; }

.u-s-p-b-7 {
  padding-bottom: 7px; }

.u-s-p-l-7 {
  padding-left: 7px; }

.u-s-p-x-7 {
  padding: 0 7px; }

.u-s-p-y-7 {
  padding: 7px 0; }

.u-s-p-xy-7 {
  padding: 7px; }

/* Margin with value `8`. */
.u-s-m-t-8 {
  margin-top: 8px; }

.u-s-m-r-8 {
  margin-right: 8px; }

.u-s-m-b-8 {
  margin-bottom: 8px; }

.u-s-m-l-8 {
  margin-left: 8px; }

.u-s-m-x-8 {
  margin: 0 8px; }

.u-s-m-y-8 {
  margin: 8px 0; }

.u-s-m-xy-8 {
  margin: 8px; }

/* Padding with value `8`. */
.u-s-p-t-8 {
  padding-top: 8px; }

.u-s-p-r-8 {
  padding-right: 8px; }

.u-s-p-b-8 {
  padding-bottom: 8px; }

.u-s-p-l-8 {
  padding-left: 8px; }

.u-s-p-x-8 {
  padding: 0 8px; }

.u-s-p-y-8 {
  padding: 8px 0; }

.u-s-p-xy-8 {
  padding: 8px; }

/* Margin with value `9`. */
.u-s-m-t-9 {
  margin-top: 9px; }

.u-s-m-r-9 {
  margin-right: 9px; }

.u-s-m-b-9 {
  margin-bottom: 9px; }

.u-s-m-l-9 {
  margin-left: 9px; }

.u-s-m-x-9 {
  margin: 0 9px; }

.u-s-m-y-9 {
  margin: 9px 0; }

.u-s-m-xy-9 {
  margin: 9px; }

/* Padding with value `9`. */
.u-s-p-t-9 {
  padding-top: 9px; }

.u-s-p-r-9 {
  padding-right: 9px; }

.u-s-p-b-9 {
  padding-bottom: 9px; }

.u-s-p-l-9 {
  padding-left: 9px; }

.u-s-p-x-9 {
  padding: 0 9px; }

.u-s-p-y-9 {
  padding: 9px 0; }

.u-s-p-xy-9 {
  padding: 9px; }

/* Margin with value `10`. */
.u-s-m-t-10 {
  margin-top: 10px; }

.u-s-m-r-10 {
  margin-right: 10px; }

.u-s-m-b-10 {
  margin-bottom: 10px; }

.u-s-m-l-10 {
  margin-left: 10px; }

.u-s-m-x-10 {
  margin: 0 10px; }

.u-s-m-y-10 {
  margin: 10px 0; }

.u-s-m-xy-10 {
  margin: 10px; }

/* Padding with value `10`. */
.u-s-p-t-10 {
  padding-top: 10px; }

.u-s-p-r-10 {
  padding-right: 10px; }

.u-s-p-b-10 {
  padding-bottom: 10px; }

.u-s-p-l-10 {
  padding-left: 10px; }

.u-s-p-x-10 {
  padding: 0 10px; }

.u-s-p-y-10 {
  padding: 10px 0; }

.u-s-p-xy-10 {
  padding: 10px; }

/* Margin with value `11`. */
.u-s-m-t-11 {
  margin-top: 11px; }

.u-s-m-r-11 {
  margin-right: 11px; }

.u-s-m-b-11 {
  margin-bottom: 11px; }

.u-s-m-l-11 {
  margin-left: 11px; }

.u-s-m-x-11 {
  margin: 0 11px; }

.u-s-m-y-11 {
  margin: 11px 0; }

.u-s-m-xy-11 {
  margin: 11px; }

/* Padding with value `11`. */
.u-s-p-t-11 {
  padding-top: 11px; }

.u-s-p-r-11 {
  padding-right: 11px; }

.u-s-p-b-11 {
  padding-bottom: 11px; }

.u-s-p-l-11 {
  padding-left: 11px; }

.u-s-p-x-11 {
  padding: 0 11px; }

.u-s-p-y-11 {
  padding: 11px 0; }

.u-s-p-xy-11 {
  padding: 11px; }

/* Margin with value `12`. */
.u-s-m-t-12 {
  margin-top: 12px; }

.u-s-m-r-12 {
  margin-right: 12px; }

.u-s-m-b-12 {
  margin-bottom: 12px; }

.u-s-m-l-12 {
  margin-left: 12px; }

.u-s-m-x-12 {
  margin: 0 12px; }

.u-s-m-y-12 {
  margin: 12px 0; }

.u-s-m-xy-12 {
  margin: 12px; }

/* Padding with value `12`. */
.u-s-p-t-12 {
  padding-top: 12px; }

.u-s-p-r-12 {
  padding-right: 12px; }

.u-s-p-b-12 {
  padding-bottom: 12px; }

.u-s-p-l-12 {
  padding-left: 12px; }

.u-s-p-x-12 {
  padding: 0 12px; }

.u-s-p-y-12 {
  padding: 12px 0; }

.u-s-p-xy-12 {
  padding: 12px; }

/* Margin with value `13`. */
.u-s-m-t-13 {
  margin-top: 13px; }

.u-s-m-r-13 {
  margin-right: 13px; }

.u-s-m-b-13 {
  margin-bottom: 13px; }

.u-s-m-l-13 {
  margin-left: 13px; }

.u-s-m-x-13 {
  margin: 0 13px; }

.u-s-m-y-13 {
  margin: 13px 0; }

.u-s-m-xy-13 {
  margin: 13px; }

/* Padding with value `13`. */
.u-s-p-t-13 {
  padding-top: 13px; }

.u-s-p-r-13 {
  padding-right: 13px; }

.u-s-p-b-13 {
  padding-bottom: 13px; }

.u-s-p-l-13 {
  padding-left: 13px; }

.u-s-p-x-13 {
  padding: 0 13px; }

.u-s-p-y-13 {
  padding: 13px 0; }

.u-s-p-xy-13 {
  padding: 13px; }

/* Margin with value `14`. */
.u-s-m-t-14 {
  margin-top: 14px; }

.u-s-m-r-14 {
  margin-right: 14px; }

.u-s-m-b-14 {
  margin-bottom: 14px; }

.u-s-m-l-14 {
  margin-left: 14px; }

.u-s-m-x-14 {
  margin: 0 14px; }

.u-s-m-y-14 {
  margin: 14px 0; }

.u-s-m-xy-14 {
  margin: 14px; }

/* Padding with value `14`. */
.u-s-p-t-14 {
  padding-top: 14px; }

.u-s-p-r-14 {
  padding-right: 14px; }

.u-s-p-b-14 {
  padding-bottom: 14px; }

.u-s-p-l-14 {
  padding-left: 14px; }

.u-s-p-x-14 {
  padding: 0 14px; }

.u-s-p-y-14 {
  padding: 14px 0; }

.u-s-p-xy-14 {
  padding: 14px; }

/* Margin with value `15`. */
.u-s-m-t-15 {
  margin-top: 15px; }

.u-s-m-r-15 {
  margin-right: 15px; }

.u-s-m-b-15 {
  margin-bottom: 15px; }

.u-s-m-l-15 {
  margin-left: 15px; }

.u-s-m-x-15 {
  margin: 0 15px; }

.u-s-m-y-15 {
  margin: 15px 0; }

.u-s-m-xy-15 {
  margin: 15px; }

/* Padding with value `15`. */
.u-s-p-t-15 {
  padding-top: 15px; }

.u-s-p-r-15 {
  padding-right: 15px; }

.u-s-p-b-15 {
  padding-bottom: 15px; }

.u-s-p-l-15 {
  padding-left: 15px; }

.u-s-p-x-15 {
  padding: 0 15px; }

.u-s-p-y-15 {
  padding: 15px 0; }

.u-s-p-xy-15 {
  padding: 15px; }

/* Margin with value `16`. */
.u-s-m-t-16 {
  margin-top: 16px; }

.u-s-m-r-16 {
  margin-right: 16px; }

.u-s-m-b-16 {
  margin-bottom: 16px; }

.u-s-m-l-16 {
  margin-left: 16px; }

.u-s-m-x-16 {
  margin: 0 16px; }

.u-s-m-y-16 {
  margin: 16px 0; }

.u-s-m-xy-16 {
  margin: 16px; }

/* Padding with value `16`. */
.u-s-p-t-16 {
  padding-top: 16px; }

.u-s-p-r-16 {
  padding-right: 16px; }

.u-s-p-b-16 {
  padding-bottom: 16px; }

.u-s-p-l-16 {
  padding-left: 16px; }

.u-s-p-x-16 {
  padding: 0 16px; }

.u-s-p-y-16 {
  padding: 16px 0; }

.u-s-p-xy-16 {
  padding: 16px; }

/* Margin with value `17`. */
.u-s-m-t-17 {
  margin-top: 17px; }

.u-s-m-r-17 {
  margin-right: 17px; }

.u-s-m-b-17 {
  margin-bottom: 17px; }

.u-s-m-l-17 {
  margin-left: 17px; }

.u-s-m-x-17 {
  margin: 0 17px; }

.u-s-m-y-17 {
  margin: 17px 0; }

.u-s-m-xy-17 {
  margin: 17px; }

/* Padding with value `17`. */
.u-s-p-t-17 {
  padding-top: 17px; }

.u-s-p-r-17 {
  padding-right: 17px; }

.u-s-p-b-17 {
  padding-bottom: 17px; }

.u-s-p-l-17 {
  padding-left: 17px; }

.u-s-p-x-17 {
  padding: 0 17px; }

.u-s-p-y-17 {
  padding: 17px 0; }

.u-s-p-xy-17 {
  padding: 17px; }

/* Margin with value `18`. */
.u-s-m-t-18 {
  margin-top: 18px; }

.u-s-m-r-18 {
  margin-right: 18px; }

.u-s-m-b-18 {
  margin-bottom: 18px; }

.u-s-m-l-18 {
  margin-left: 18px; }

.u-s-m-x-18 {
  margin: 0 18px; }

.u-s-m-y-18 {
  margin: 18px 0; }

.u-s-m-xy-18 {
  margin: 18px; }

/* Padding with value `18`. */
.u-s-p-t-18 {
  padding-top: 18px; }

.u-s-p-r-18 {
  padding-right: 18px; }

.u-s-p-b-18 {
  padding-bottom: 18px; }

.u-s-p-l-18 {
  padding-left: 18px; }

.u-s-p-x-18 {
  padding: 0 18px; }

.u-s-p-y-18 {
  padding: 18px 0; }

.u-s-p-xy-18 {
  padding: 18px; }

/* Margin with value `19`. */
.u-s-m-t-19 {
  margin-top: 19px; }

.u-s-m-r-19 {
  margin-right: 19px; }

.u-s-m-b-19 {
  margin-bottom: 19px; }

.u-s-m-l-19 {
  margin-left: 19px; }

.u-s-m-x-19 {
  margin: 0 19px; }

.u-s-m-y-19 {
  margin: 19px 0; }

.u-s-m-xy-19 {
  margin: 19px; }

/* Padding with value `19`. */
.u-s-p-t-19 {
  padding-top: 19px; }

.u-s-p-r-19 {
  padding-right: 19px; }

.u-s-p-b-19 {
  padding-bottom: 19px; }

.u-s-p-l-19 {
  padding-left: 19px; }

.u-s-p-x-19 {
  padding: 0 19px; }

.u-s-p-y-19 {
  padding: 19px 0; }

.u-s-p-xy-19 {
  padding: 19px; }

/* Margin with value `20`. */
.u-s-m-t-20 {
  margin-top: 20px; }

.u-s-m-r-20 {
  margin-right: 20px; }

.u-s-m-b-20 {
  margin-bottom: 20px; }

.u-s-m-l-20 {
  margin-left: 20px; }

.u-s-m-x-20 {
  margin: 0 20px; }

.u-s-m-y-20 {
  margin: 20px 0; }

.u-s-m-xy-20 {
  margin: 20px; }

/* Padding with value `20`. */
.u-s-p-t-20 {
  padding-top: 20px; }

.u-s-p-r-20 {
  padding-right: 20px; }

.u-s-p-b-20 {
  padding-bottom: 20px; }

.u-s-p-l-20 {
  padding-left: 20px; }

.u-s-p-x-20 {
  padding: 0 20px; }

.u-s-p-y-20 {
  padding: 20px 0; }

.u-s-p-xy-20 {
  padding: 20px; }

/* Margin with value `21`. */
.u-s-m-t-21 {
  margin-top: 21px; }

.u-s-m-r-21 {
  margin-right: 21px; }

.u-s-m-b-21 {
  margin-bottom: 21px; }

.u-s-m-l-21 {
  margin-left: 21px; }

.u-s-m-x-21 {
  margin: 0 21px; }

.u-s-m-y-21 {
  margin: 21px 0; }

.u-s-m-xy-21 {
  margin: 21px; }

/* Padding with value `21`. */
.u-s-p-t-21 {
  padding-top: 21px; }

.u-s-p-r-21 {
  padding-right: 21px; }

.u-s-p-b-21 {
  padding-bottom: 21px; }

.u-s-p-l-21 {
  padding-left: 21px; }

.u-s-p-x-21 {
  padding: 0 21px; }

.u-s-p-y-21 {
  padding: 21px 0; }

.u-s-p-xy-21 {
  padding: 21px; }

/* Margin with value `22`. */
.u-s-m-t-22 {
  margin-top: 22px; }

.u-s-m-r-22 {
  margin-right: 22px; }

.u-s-m-b-22 {
  margin-bottom: 22px; }

.u-s-m-l-22 {
  margin-left: 22px; }

.u-s-m-x-22 {
  margin: 0 22px; }

.u-s-m-y-22 {
  margin: 22px 0; }

.u-s-m-xy-22 {
  margin: 22px; }

/* Padding with value `22`. */
.u-s-p-t-22 {
  padding-top: 22px; }

.u-s-p-r-22 {
  padding-right: 22px; }

.u-s-p-b-22 {
  padding-bottom: 22px; }

.u-s-p-l-22 {
  padding-left: 22px; }

.u-s-p-x-22 {
  padding: 0 22px; }

.u-s-p-y-22 {
  padding: 22px 0; }

.u-s-p-xy-22 {
  padding: 22px; }

/* Margin with value `23`. */
.u-s-m-t-23 {
  margin-top: 23px; }

.u-s-m-r-23 {
  margin-right: 23px; }

.u-s-m-b-23 {
  margin-bottom: 23px; }

.u-s-m-l-23 {
  margin-left: 23px; }

.u-s-m-x-23 {
  margin: 0 23px; }

.u-s-m-y-23 {
  margin: 23px 0; }

.u-s-m-xy-23 {
  margin: 23px; }

/* Padding with value `23`. */
.u-s-p-t-23 {
  padding-top: 23px; }

.u-s-p-r-23 {
  padding-right: 23px; }

.u-s-p-b-23 {
  padding-bottom: 23px; }

.u-s-p-l-23 {
  padding-left: 23px; }

.u-s-p-x-23 {
  padding: 0 23px; }

.u-s-p-y-23 {
  padding: 23px 0; }

.u-s-p-xy-23 {
  padding: 23px; }

/* Margin with value `24`. */
.u-s-m-t-24 {
  margin-top: 24px; }

.u-s-m-r-24 {
  margin-right: 24px; }

.u-s-m-b-24 {
  margin-bottom: 24px; }

.u-s-m-l-24 {
  margin-left: 24px; }

.u-s-m-x-24 {
  margin: 0 24px; }

.u-s-m-y-24 {
  margin: 24px 0; }

.u-s-m-xy-24 {
  margin: 24px; }

/* Padding with value `24`. */
.u-s-p-t-24 {
  padding-top: 24px; }

.u-s-p-r-24 {
  padding-right: 24px; }

.u-s-p-b-24 {
  padding-bottom: 24px; }

.u-s-p-l-24 {
  padding-left: 24px; }

.u-s-p-x-24 {
  padding: 0 24px; }

.u-s-p-y-24 {
  padding: 24px 0; }

.u-s-p-xy-24 {
  padding: 24px; }

/* Margin with value `25`. */
.u-s-m-t-25 {
  margin-top: 25px; }

.u-s-m-r-25 {
  margin-right: 25px; }

.u-s-m-b-25 {
  margin-bottom: 25px; }

.u-s-m-l-25 {
  margin-left: 25px; }

.u-s-m-x-25 {
  margin: 0 25px; }

.u-s-m-y-25 {
  margin: 25px 0; }

.u-s-m-xy-25 {
  margin: 25px; }

/* Padding with value `25`. */
.u-s-p-t-25 {
  padding-top: 25px; }

.u-s-p-r-25 {
  padding-right: 25px; }

.u-s-p-b-25 {
  padding-bottom: 25px; }

.u-s-p-l-25 {
  padding-left: 25px; }

.u-s-p-x-25 {
  padding: 0 25px; }

.u-s-p-y-25 {
  padding: 25px 0; }

.u-s-p-xy-25 {
  padding: 25px; }

/* Margin with value `26`. */
.u-s-m-t-26 {
  margin-top: 26px; }

.u-s-m-r-26 {
  margin-right: 26px; }

.u-s-m-b-26 {
  margin-bottom: 26px; }

.u-s-m-l-26 {
  margin-left: 26px; }

.u-s-m-x-26 {
  margin: 0 26px; }

.u-s-m-y-26 {
  margin: 26px 0; }

.u-s-m-xy-26 {
  margin: 26px; }

/* Padding with value `26`. */
.u-s-p-t-26 {
  padding-top: 26px; }

.u-s-p-r-26 {
  padding-right: 26px; }

.u-s-p-b-26 {
  padding-bottom: 26px; }

.u-s-p-l-26 {
  padding-left: 26px; }

.u-s-p-x-26 {
  padding: 0 26px; }

.u-s-p-y-26 {
  padding: 26px 0; }

.u-s-p-xy-26 {
  padding: 26px; }

/* Margin with value `27`. */
.u-s-m-t-27 {
  margin-top: 27px; }

.u-s-m-r-27 {
  margin-right: 27px; }

.u-s-m-b-27 {
  margin-bottom: 27px; }

.u-s-m-l-27 {
  margin-left: 27px; }

.u-s-m-x-27 {
  margin: 0 27px; }

.u-s-m-y-27 {
  margin: 27px 0; }

.u-s-m-xy-27 {
  margin: 27px; }

/* Padding with value `27`. */
.u-s-p-t-27 {
  padding-top: 27px; }

.u-s-p-r-27 {
  padding-right: 27px; }

.u-s-p-b-27 {
  padding-bottom: 27px; }

.u-s-p-l-27 {
  padding-left: 27px; }

.u-s-p-x-27 {
  padding: 0 27px; }

.u-s-p-y-27 {
  padding: 27px 0; }

.u-s-p-xy-27 {
  padding: 27px; }

/* Margin with value `28`. */
.u-s-m-t-28 {
  margin-top: 28px; }

.u-s-m-r-28 {
  margin-right: 28px; }

.u-s-m-b-28 {
  margin-bottom: 28px; }

.u-s-m-l-28 {
  margin-left: 28px; }

.u-s-m-x-28 {
  margin: 0 28px; }

.u-s-m-y-28 {
  margin: 28px 0; }

.u-s-m-xy-28 {
  margin: 28px; }

/* Padding with value `28`. */
.u-s-p-t-28 {
  padding-top: 28px; }

.u-s-p-r-28 {
  padding-right: 28px; }

.u-s-p-b-28 {
  padding-bottom: 28px; }

.u-s-p-l-28 {
  padding-left: 28px; }

.u-s-p-x-28 {
  padding: 0 28px; }

.u-s-p-y-28 {
  padding: 28px 0; }

.u-s-p-xy-28 {
  padding: 28px; }

/* Margin with value `29`. */
.u-s-m-t-29 {
  margin-top: 29px; }

.u-s-m-r-29 {
  margin-right: 29px; }

.u-s-m-b-29 {
  margin-bottom: 29px; }

.u-s-m-l-29 {
  margin-left: 29px; }

.u-s-m-x-29 {
  margin: 0 29px; }

.u-s-m-y-29 {
  margin: 29px 0; }

.u-s-m-xy-29 {
  margin: 29px; }

/* Padding with value `29`. */
.u-s-p-t-29 {
  padding-top: 29px; }

.u-s-p-r-29 {
  padding-right: 29px; }

.u-s-p-b-29 {
  padding-bottom: 29px; }

.u-s-p-l-29 {
  padding-left: 29px; }

.u-s-p-x-29 {
  padding: 0 29px; }

.u-s-p-y-29 {
  padding: 29px 0; }

.u-s-p-xy-29 {
  padding: 29px; }

/* Margin with value `30`. */
.u-s-m-t-30 {
  margin-top: 30px; }

.u-s-m-r-30 {
  margin-right: 30px; }

.u-s-m-b-30 {
  margin-bottom: 30px; }

.u-s-m-l-30 {
  margin-left: 30px; }

.u-s-m-x-30 {
  margin: 0 30px; }

.u-s-m-y-30 {
  margin: 30px 0; }

.u-s-m-xy-30 {
  margin: 30px; }

/* Padding with value `30`. */
.u-s-p-t-30 {
  padding-top: 30px; }

.u-s-p-r-30 {
  padding-right: 30px; }

.u-s-p-b-30 {
  padding-bottom: 30px; }

.u-s-p-l-30 {
  padding-left: 30px; }

.u-s-p-x-30 {
  padding: 0 30px; }

.u-s-p-y-30 {
  padding: 30px 0; }

.u-s-p-xy-30 {
  padding: 30px; }

/* Margin with value `31`. */
.u-s-m-t-31 {
  margin-top: 31px; }

.u-s-m-r-31 {
  margin-right: 31px; }

.u-s-m-b-31 {
  margin-bottom: 31px; }

.u-s-m-l-31 {
  margin-left: 31px; }

.u-s-m-x-31 {
  margin: 0 31px; }

.u-s-m-y-31 {
  margin: 31px 0; }

.u-s-m-xy-31 {
  margin: 31px; }

/* Padding with value `31`. */
.u-s-p-t-31 {
  padding-top: 31px; }

.u-s-p-r-31 {
  padding-right: 31px; }

.u-s-p-b-31 {
  padding-bottom: 31px; }

.u-s-p-l-31 {
  padding-left: 31px; }

.u-s-p-x-31 {
  padding: 0 31px; }

.u-s-p-y-31 {
  padding: 31px 0; }

.u-s-p-xy-31 {
  padding: 31px; }

/* Margin with value `32`. */
.u-s-m-t-32 {
  margin-top: 32px; }

.u-s-m-r-32 {
  margin-right: 32px; }

.u-s-m-b-32 {
  margin-bottom: 32px; }

.u-s-m-l-32 {
  margin-left: 32px; }

.u-s-m-x-32 {
  margin: 0 32px; }

.u-s-m-y-32 {
  margin: 32px 0; }

.u-s-m-xy-32 {
  margin: 32px; }

/* Padding with value `32`. */
.u-s-p-t-32 {
  padding-top: 32px; }

.u-s-p-r-32 {
  padding-right: 32px; }

.u-s-p-b-32 {
  padding-bottom: 32px; }

.u-s-p-l-32 {
  padding-left: 32px; }

.u-s-p-x-32 {
  padding: 0 32px; }

.u-s-p-y-32 {
  padding: 32px 0; }

.u-s-p-xy-32 {
  padding: 32px; }

/* Margin with value `33`. */
.u-s-m-t-33 {
  margin-top: 33px; }

.u-s-m-r-33 {
  margin-right: 33px; }

.u-s-m-b-33 {
  margin-bottom: 33px; }

.u-s-m-l-33 {
  margin-left: 33px; }

.u-s-m-x-33 {
  margin: 0 33px; }

.u-s-m-y-33 {
  margin: 33px 0; }

.u-s-m-xy-33 {
  margin: 33px; }

/* Padding with value `33`. */
.u-s-p-t-33 {
  padding-top: 33px; }

.u-s-p-r-33 {
  padding-right: 33px; }

.u-s-p-b-33 {
  padding-bottom: 33px; }

.u-s-p-l-33 {
  padding-left: 33px; }

.u-s-p-x-33 {
  padding: 0 33px; }

.u-s-p-y-33 {
  padding: 33px 0; }

.u-s-p-xy-33 {
  padding: 33px; }

/* Margin with value `34`. */
.u-s-m-t-34 {
  margin-top: 34px; }

.u-s-m-r-34 {
  margin-right: 34px; }

.u-s-m-b-34 {
  margin-bottom: 34px; }

.u-s-m-l-34 {
  margin-left: 34px; }

.u-s-m-x-34 {
  margin: 0 34px; }

.u-s-m-y-34 {
  margin: 34px 0; }

.u-s-m-xy-34 {
  margin: 34px; }

/* Padding with value `34`. */
.u-s-p-t-34 {
  padding-top: 34px; }

.u-s-p-r-34 {
  padding-right: 34px; }

.u-s-p-b-34 {
  padding-bottom: 34px; }

.u-s-p-l-34 {
  padding-left: 34px; }

.u-s-p-x-34 {
  padding: 0 34px; }

.u-s-p-y-34 {
  padding: 34px 0; }

.u-s-p-xy-34 {
  padding: 34px; }

/* Margin with value `35`. */
.u-s-m-t-35 {
  margin-top: 35px; }

.u-s-m-r-35 {
  margin-right: 35px; }

.u-s-m-b-35 {
  margin-bottom: 35px; }

.u-s-m-l-35 {
  margin-left: 35px; }

.u-s-m-x-35 {
  margin: 0 35px; }

.u-s-m-y-35 {
  margin: 35px 0; }

.u-s-m-xy-35 {
  margin: 35px; }

/* Padding with value `35`. */
.u-s-p-t-35 {
  padding-top: 35px; }

.u-s-p-r-35 {
  padding-right: 35px; }

.u-s-p-b-35 {
  padding-bottom: 35px; }

.u-s-p-l-35 {
  padding-left: 35px; }

.u-s-p-x-35 {
  padding: 0 35px; }

.u-s-p-y-35 {
  padding: 35px 0; }

.u-s-p-xy-35 {
  padding: 35px; }

/* Margin with value `36`. */
.u-s-m-t-36 {
  margin-top: 36px; }

.u-s-m-r-36 {
  margin-right: 36px; }

.u-s-m-b-36 {
  margin-bottom: 36px; }

.u-s-m-l-36 {
  margin-left: 36px; }

.u-s-m-x-36 {
  margin: 0 36px; }

.u-s-m-y-36 {
  margin: 36px 0; }

.u-s-m-xy-36 {
  margin: 36px; }

/* Padding with value `36`. */
.u-s-p-t-36 {
  padding-top: 36px; }

.u-s-p-r-36 {
  padding-right: 36px; }

.u-s-p-b-36 {
  padding-bottom: 36px; }

.u-s-p-l-36 {
  padding-left: 36px; }

.u-s-p-x-36 {
  padding: 0 36px; }

.u-s-p-y-36 {
  padding: 36px 0; }

.u-s-p-xy-36 {
  padding: 36px; }

/* Margin with value `37`. */
.u-s-m-t-37 {
  margin-top: 37px; }

.u-s-m-r-37 {
  margin-right: 37px; }

.u-s-m-b-37 {
  margin-bottom: 37px; }

.u-s-m-l-37 {
  margin-left: 37px; }

.u-s-m-x-37 {
  margin: 0 37px; }

.u-s-m-y-37 {
  margin: 37px 0; }

.u-s-m-xy-37 {
  margin: 37px; }

/* Padding with value `37`. */
.u-s-p-t-37 {
  padding-top: 37px; }

.u-s-p-r-37 {
  padding-right: 37px; }

.u-s-p-b-37 {
  padding-bottom: 37px; }

.u-s-p-l-37 {
  padding-left: 37px; }

.u-s-p-x-37 {
  padding: 0 37px; }

.u-s-p-y-37 {
  padding: 37px 0; }

.u-s-p-xy-37 {
  padding: 37px; }

/* Margin with value `38`. */
.u-s-m-t-38 {
  margin-top: 38px; }

.u-s-m-r-38 {
  margin-right: 38px; }

.u-s-m-b-38 {
  margin-bottom: 38px; }

.u-s-m-l-38 {
  margin-left: 38px; }

.u-s-m-x-38 {
  margin: 0 38px; }

.u-s-m-y-38 {
  margin: 38px 0; }

.u-s-m-xy-38 {
  margin: 38px; }

/* Padding with value `38`. */
.u-s-p-t-38 {
  padding-top: 38px; }

.u-s-p-r-38 {
  padding-right: 38px; }

.u-s-p-b-38 {
  padding-bottom: 38px; }

.u-s-p-l-38 {
  padding-left: 38px; }

.u-s-p-x-38 {
  padding: 0 38px; }

.u-s-p-y-38 {
  padding: 38px 0; }

.u-s-p-xy-38 {
  padding: 38px; }

/* Margin with value `39`. */
.u-s-m-t-39 {
  margin-top: 39px; }

.u-s-m-r-39 {
  margin-right: 39px; }

.u-s-m-b-39 {
  margin-bottom: 39px; }

.u-s-m-l-39 {
  margin-left: 39px; }

.u-s-m-x-39 {
  margin: 0 39px; }

.u-s-m-y-39 {
  margin: 39px 0; }

.u-s-m-xy-39 {
  margin: 39px; }

/* Padding with value `39`. */
.u-s-p-t-39 {
  padding-top: 39px; }

.u-s-p-r-39 {
  padding-right: 39px; }

.u-s-p-b-39 {
  padding-bottom: 39px; }

.u-s-p-l-39 {
  padding-left: 39px; }

.u-s-p-x-39 {
  padding: 0 39px; }

.u-s-p-y-39 {
  padding: 39px 0; }

.u-s-p-xy-39 {
  padding: 39px; }

/* Margin with value `40`. */
.u-s-m-t-40 {
  margin-top: 40px; }

.u-s-m-r-40 {
  margin-right: 40px; }

.u-s-m-b-40 {
  margin-bottom: 40px; }

.u-s-m-l-40 {
  margin-left: 40px; }

.u-s-m-x-40 {
  margin: 0 40px; }

.u-s-m-y-40 {
  margin: 40px 0; }

.u-s-m-xy-40 {
  margin: 40px; }

/* Padding with value `40`. */
.u-s-p-t-40 {
  padding-top: 40px; }

.u-s-p-r-40 {
  padding-right: 40px; }

.u-s-p-b-40 {
  padding-bottom: 40px; }

.u-s-p-l-40 {
  padding-left: 40px; }

.u-s-p-x-40 {
  padding: 0 40px; }

.u-s-p-y-40 {
  padding: 40px 0; }

.u-s-p-xy-40 {
  padding: 40px; }

/* Margin with value `41`. */
.u-s-m-t-41 {
  margin-top: 41px; }

.u-s-m-r-41 {
  margin-right: 41px; }

.u-s-m-b-41 {
  margin-bottom: 41px; }

.u-s-m-l-41 {
  margin-left: 41px; }

.u-s-m-x-41 {
  margin: 0 41px; }

.u-s-m-y-41 {
  margin: 41px 0; }

.u-s-m-xy-41 {
  margin: 41px; }

/* Padding with value `41`. */
.u-s-p-t-41 {
  padding-top: 41px; }

.u-s-p-r-41 {
  padding-right: 41px; }

.u-s-p-b-41 {
  padding-bottom: 41px; }

.u-s-p-l-41 {
  padding-left: 41px; }

.u-s-p-x-41 {
  padding: 0 41px; }

.u-s-p-y-41 {
  padding: 41px 0; }

.u-s-p-xy-41 {
  padding: 41px; }

/* Margin with value `42`. */
.u-s-m-t-42 {
  margin-top: 42px; }

.u-s-m-r-42 {
  margin-right: 42px; }

.u-s-m-b-42 {
  margin-bottom: 42px; }

.u-s-m-l-42 {
  margin-left: 42px; }

.u-s-m-x-42 {
  margin: 0 42px; }

.u-s-m-y-42 {
  margin: 42px 0; }

.u-s-m-xy-42 {
  margin: 42px; }

/* Padding with value `42`. */
.u-s-p-t-42 {
  padding-top: 42px; }

.u-s-p-r-42 {
  padding-right: 42px; }

.u-s-p-b-42 {
  padding-bottom: 42px; }

.u-s-p-l-42 {
  padding-left: 42px; }

.u-s-p-x-42 {
  padding: 0 42px; }

.u-s-p-y-42 {
  padding: 42px 0; }

.u-s-p-xy-42 {
  padding: 42px; }

/* Margin with value `43`. */
.u-s-m-t-43 {
  margin-top: 43px; }

.u-s-m-r-43 {
  margin-right: 43px; }

.u-s-m-b-43 {
  margin-bottom: 43px; }

.u-s-m-l-43 {
  margin-left: 43px; }

.u-s-m-x-43 {
  margin: 0 43px; }

.u-s-m-y-43 {
  margin: 43px 0; }

.u-s-m-xy-43 {
  margin: 43px; }

/* Padding with value `43`. */
.u-s-p-t-43 {
  padding-top: 43px; }

.u-s-p-r-43 {
  padding-right: 43px; }

.u-s-p-b-43 {
  padding-bottom: 43px; }

.u-s-p-l-43 {
  padding-left: 43px; }

.u-s-p-x-43 {
  padding: 0 43px; }

.u-s-p-y-43 {
  padding: 43px 0; }

.u-s-p-xy-43 {
  padding: 43px; }

/* Margin with value `44`. */
.u-s-m-t-44 {
  margin-top: 44px; }

.u-s-m-r-44 {
  margin-right: 44px; }

.u-s-m-b-44 {
  margin-bottom: 44px; }

.u-s-m-l-44 {
  margin-left: 44px; }

.u-s-m-x-44 {
  margin: 0 44px; }

.u-s-m-y-44 {
  margin: 44px 0; }

.u-s-m-xy-44 {
  margin: 44px; }

/* Padding with value `44`. */
.u-s-p-t-44 {
  padding-top: 44px; }

.u-s-p-r-44 {
  padding-right: 44px; }

.u-s-p-b-44 {
  padding-bottom: 44px; }

.u-s-p-l-44 {
  padding-left: 44px; }

.u-s-p-x-44 {
  padding: 0 44px; }

.u-s-p-y-44 {
  padding: 44px 0; }

.u-s-p-xy-44 {
  padding: 44px; }

/* Margin with value `45`. */
.u-s-m-t-45 {
  margin-top: 45px; }

.u-s-m-r-45 {
  margin-right: 45px; }

.u-s-m-b-45 {
  margin-bottom: 45px; }

.u-s-m-l-45 {
  margin-left: 45px; }

.u-s-m-x-45 {
  margin: 0 45px; }

.u-s-m-y-45 {
  margin: 45px 0; }

.u-s-m-xy-45 {
  margin: 45px; }

/* Padding with value `45`. */
.u-s-p-t-45 {
  padding-top: 45px; }

.u-s-p-r-45 {
  padding-right: 45px; }

.u-s-p-b-45 {
  padding-bottom: 45px; }

.u-s-p-l-45 {
  padding-left: 45px; }

.u-s-p-x-45 {
  padding: 0 45px; }

.u-s-p-y-45 {
  padding: 45px 0; }

.u-s-p-xy-45 {
  padding: 45px; }

/* Margin with value `46`. */
.u-s-m-t-46 {
  margin-top: 46px; }

.u-s-m-r-46 {
  margin-right: 46px; }

.u-s-m-b-46 {
  margin-bottom: 46px; }

.u-s-m-l-46 {
  margin-left: 46px; }

.u-s-m-x-46 {
  margin: 0 46px; }

.u-s-m-y-46 {
  margin: 46px 0; }

.u-s-m-xy-46 {
  margin: 46px; }

/* Padding with value `46`. */
.u-s-p-t-46 {
  padding-top: 46px; }

.u-s-p-r-46 {
  padding-right: 46px; }

.u-s-p-b-46 {
  padding-bottom: 46px; }

.u-s-p-l-46 {
  padding-left: 46px; }

.u-s-p-x-46 {
  padding: 0 46px; }

.u-s-p-y-46 {
  padding: 46px 0; }

.u-s-p-xy-46 {
  padding: 46px; }

/* Margin with value `47`. */
.u-s-m-t-47 {
  margin-top: 47px; }

.u-s-m-r-47 {
  margin-right: 47px; }

.u-s-m-b-47 {
  margin-bottom: 47px; }

.u-s-m-l-47 {
  margin-left: 47px; }

.u-s-m-x-47 {
  margin: 0 47px; }

.u-s-m-y-47 {
  margin: 47px 0; }

.u-s-m-xy-47 {
  margin: 47px; }

/* Padding with value `47`. */
.u-s-p-t-47 {
  padding-top: 47px; }

.u-s-p-r-47 {
  padding-right: 47px; }

.u-s-p-b-47 {
  padding-bottom: 47px; }

.u-s-p-l-47 {
  padding-left: 47px; }

.u-s-p-x-47 {
  padding: 0 47px; }

.u-s-p-y-47 {
  padding: 47px 0; }

.u-s-p-xy-47 {
  padding: 47px; }

/* Margin with value `48`. */
.u-s-m-t-48 {
  margin-top: 48px; }

.u-s-m-r-48 {
  margin-right: 48px; }

.u-s-m-b-48 {
  margin-bottom: 48px; }

.u-s-m-l-48 {
  margin-left: 48px; }

.u-s-m-x-48 {
  margin: 0 48px; }

.u-s-m-y-48 {
  margin: 48px 0; }

.u-s-m-xy-48 {
  margin: 48px; }

/* Padding with value `48`. */
.u-s-p-t-48 {
  padding-top: 48px; }

.u-s-p-r-48 {
  padding-right: 48px; }

.u-s-p-b-48 {
  padding-bottom: 48px; }

.u-s-p-l-48 {
  padding-left: 48px; }

.u-s-p-x-48 {
  padding: 0 48px; }

.u-s-p-y-48 {
  padding: 48px 0; }

.u-s-p-xy-48 {
  padding: 48px; }

/* Margin with value `49`. */
.u-s-m-t-49 {
  margin-top: 49px; }

.u-s-m-r-49 {
  margin-right: 49px; }

.u-s-m-b-49 {
  margin-bottom: 49px; }

.u-s-m-l-49 {
  margin-left: 49px; }

.u-s-m-x-49 {
  margin: 0 49px; }

.u-s-m-y-49 {
  margin: 49px 0; }

.u-s-m-xy-49 {
  margin: 49px; }

/* Padding with value `49`. */
.u-s-p-t-49 {
  padding-top: 49px; }

.u-s-p-r-49 {
  padding-right: 49px; }

.u-s-p-b-49 {
  padding-bottom: 49px; }

.u-s-p-l-49 {
  padding-left: 49px; }

.u-s-p-x-49 {
  padding: 0 49px; }

.u-s-p-y-49 {
  padding: 49px 0; }

.u-s-p-xy-49 {
  padding: 49px; }

/* Margin with value `50`. */
.u-s-m-t-50 {
  margin-top: 50px; }

.u-s-m-r-50 {
  margin-right: 50px; }

.u-s-m-b-50 {
  margin-bottom: 50px; }

.u-s-m-l-50 {
  margin-left: 50px; }

.u-s-m-x-50 {
  margin: 0 50px; }

.u-s-m-y-50 {
  margin: 50px 0; }

.u-s-m-xy-50 {
  margin: 50px; }

/* Padding with value `50`. */
.u-s-p-t-50 {
  padding-top: 50px; }

.u-s-p-r-50 {
  padding-right: 50px; }

.u-s-p-b-50 {
  padding-bottom: 50px; }

.u-s-p-l-50 {
  padding-left: 50px; }

.u-s-p-x-50 {
  padding: 0 50px; }

.u-s-p-y-50 {
  padding: 50px 0; }

.u-s-p-xy-50 {
  padding: 50px; }

/* Margin with value `51`. */
.u-s-m-t-51 {
  margin-top: 51px; }

.u-s-m-r-51 {
  margin-right: 51px; }

.u-s-m-b-51 {
  margin-bottom: 51px; }

.u-s-m-l-51 {
  margin-left: 51px; }

.u-s-m-x-51 {
  margin: 0 51px; }

.u-s-m-y-51 {
  margin: 51px 0; }

.u-s-m-xy-51 {
  margin: 51px; }

/* Padding with value `51`. */
.u-s-p-t-51 {
  padding-top: 51px; }

.u-s-p-r-51 {
  padding-right: 51px; }

.u-s-p-b-51 {
  padding-bottom: 51px; }

.u-s-p-l-51 {
  padding-left: 51px; }

.u-s-p-x-51 {
  padding: 0 51px; }

.u-s-p-y-51 {
  padding: 51px 0; }

.u-s-p-xy-51 {
  padding: 51px; }

/* Margin with value `52`. */
.u-s-m-t-52 {
  margin-top: 52px; }

.u-s-m-r-52 {
  margin-right: 52px; }

.u-s-m-b-52 {
  margin-bottom: 52px; }

.u-s-m-l-52 {
  margin-left: 52px; }

.u-s-m-x-52 {
  margin: 0 52px; }

.u-s-m-y-52 {
  margin: 52px 0; }

.u-s-m-xy-52 {
  margin: 52px; }

/* Padding with value `52`. */
.u-s-p-t-52 {
  padding-top: 52px; }

.u-s-p-r-52 {
  padding-right: 52px; }

.u-s-p-b-52 {
  padding-bottom: 52px; }

.u-s-p-l-52 {
  padding-left: 52px; }

.u-s-p-x-52 {
  padding: 0 52px; }

.u-s-p-y-52 {
  padding: 52px 0; }

.u-s-p-xy-52 {
  padding: 52px; }

/* Margin with value `53`. */
.u-s-m-t-53 {
  margin-top: 53px; }

.u-s-m-r-53 {
  margin-right: 53px; }

.u-s-m-b-53 {
  margin-bottom: 53px; }

.u-s-m-l-53 {
  margin-left: 53px; }

.u-s-m-x-53 {
  margin: 0 53px; }

.u-s-m-y-53 {
  margin: 53px 0; }

.u-s-m-xy-53 {
  margin: 53px; }

/* Padding with value `53`. */
.u-s-p-t-53 {
  padding-top: 53px; }

.u-s-p-r-53 {
  padding-right: 53px; }

.u-s-p-b-53 {
  padding-bottom: 53px; }

.u-s-p-l-53 {
  padding-left: 53px; }

.u-s-p-x-53 {
  padding: 0 53px; }

.u-s-p-y-53 {
  padding: 53px 0; }

.u-s-p-xy-53 {
  padding: 53px; }

/* Margin with value `54`. */
.u-s-m-t-54 {
  margin-top: 54px; }

.u-s-m-r-54 {
  margin-right: 54px; }

.u-s-m-b-54 {
  margin-bottom: 54px; }

.u-s-m-l-54 {
  margin-left: 54px; }

.u-s-m-x-54 {
  margin: 0 54px; }

.u-s-m-y-54 {
  margin: 54px 0; }

.u-s-m-xy-54 {
  margin: 54px; }

/* Padding with value `54`. */
.u-s-p-t-54 {
  padding-top: 54px; }

.u-s-p-r-54 {
  padding-right: 54px; }

.u-s-p-b-54 {
  padding-bottom: 54px; }

.u-s-p-l-54 {
  padding-left: 54px; }

.u-s-p-x-54 {
  padding: 0 54px; }

.u-s-p-y-54 {
  padding: 54px 0; }

.u-s-p-xy-54 {
  padding: 54px; }

/* Margin with value `55`. */
.u-s-m-t-55 {
  margin-top: 55px; }

.u-s-m-r-55 {
  margin-right: 55px; }

.u-s-m-b-55 {
  margin-bottom: 55px; }

.u-s-m-l-55 {
  margin-left: 55px; }

.u-s-m-x-55 {
  margin: 0 55px; }

.u-s-m-y-55 {
  margin: 55px 0; }

.u-s-m-xy-55 {
  margin: 55px; }

/* Padding with value `55`. */
.u-s-p-t-55 {
  padding-top: 55px; }

.u-s-p-r-55 {
  padding-right: 55px; }

.u-s-p-b-55 {
  padding-bottom: 55px; }

.u-s-p-l-55 {
  padding-left: 55px; }

.u-s-p-x-55 {
  padding: 0 55px; }

.u-s-p-y-55 {
  padding: 55px 0; }

.u-s-p-xy-55 {
  padding: 55px; }

/* Margin with value `56`. */
.u-s-m-t-56 {
  margin-top: 56px; }

.u-s-m-r-56 {
  margin-right: 56px; }

.u-s-m-b-56 {
  margin-bottom: 56px; }

.u-s-m-l-56 {
  margin-left: 56px; }

.u-s-m-x-56 {
  margin: 0 56px; }

.u-s-m-y-56 {
  margin: 56px 0; }

.u-s-m-xy-56 {
  margin: 56px; }

/* Padding with value `56`. */
.u-s-p-t-56 {
  padding-top: 56px; }

.u-s-p-r-56 {
  padding-right: 56px; }

.u-s-p-b-56 {
  padding-bottom: 56px; }

.u-s-p-l-56 {
  padding-left: 56px; }

.u-s-p-x-56 {
  padding: 0 56px; }

.u-s-p-y-56 {
  padding: 56px 0; }

.u-s-p-xy-56 {
  padding: 56px; }

/* Margin with value `57`. */
.u-s-m-t-57 {
  margin-top: 57px; }

.u-s-m-r-57 {
  margin-right: 57px; }

.u-s-m-b-57 {
  margin-bottom: 57px; }

.u-s-m-l-57 {
  margin-left: 57px; }

.u-s-m-x-57 {
  margin: 0 57px; }

.u-s-m-y-57 {
  margin: 57px 0; }

.u-s-m-xy-57 {
  margin: 57px; }

/* Padding with value `57`. */
.u-s-p-t-57 {
  padding-top: 57px; }

.u-s-p-r-57 {
  padding-right: 57px; }

.u-s-p-b-57 {
  padding-bottom: 57px; }

.u-s-p-l-57 {
  padding-left: 57px; }

.u-s-p-x-57 {
  padding: 0 57px; }

.u-s-p-y-57 {
  padding: 57px 0; }

.u-s-p-xy-57 {
  padding: 57px; }

/* Margin with value `58`. */
.u-s-m-t-58 {
  margin-top: 58px; }

.u-s-m-r-58 {
  margin-right: 58px; }

.u-s-m-b-58 {
  margin-bottom: 58px; }

.u-s-m-l-58 {
  margin-left: 58px; }

.u-s-m-x-58 {
  margin: 0 58px; }

.u-s-m-y-58 {
  margin: 58px 0; }

.u-s-m-xy-58 {
  margin: 58px; }

/* Padding with value `58`. */
.u-s-p-t-58 {
  padding-top: 58px; }

.u-s-p-r-58 {
  padding-right: 58px; }

.u-s-p-b-58 {
  padding-bottom: 58px; }

.u-s-p-l-58 {
  padding-left: 58px; }

.u-s-p-x-58 {
  padding: 0 58px; }

.u-s-p-y-58 {
  padding: 58px 0; }

.u-s-p-xy-58 {
  padding: 58px; }

/* Margin with value `59`. */
.u-s-m-t-59 {
  margin-top: 59px; }

.u-s-m-r-59 {
  margin-right: 59px; }

.u-s-m-b-59 {
  margin-bottom: 59px; }

.u-s-m-l-59 {
  margin-left: 59px; }

.u-s-m-x-59 {
  margin: 0 59px; }

.u-s-m-y-59 {
  margin: 59px 0; }

.u-s-m-xy-59 {
  margin: 59px; }

/* Padding with value `59`. */
.u-s-p-t-59 {
  padding-top: 59px; }

.u-s-p-r-59 {
  padding-right: 59px; }

.u-s-p-b-59 {
  padding-bottom: 59px; }

.u-s-p-l-59 {
  padding-left: 59px; }

.u-s-p-x-59 {
  padding: 0 59px; }

.u-s-p-y-59 {
  padding: 59px 0; }

.u-s-p-xy-59 {
  padding: 59px; }

/* Margin with value `60`. */
.u-s-m-t-60 {
  margin-top: 60px; }

.u-s-m-r-60 {
  margin-right: 60px; }

.u-s-m-b-60 {
  margin-bottom: 60px; }

.u-s-m-l-60 {
  margin-left: 60px; }

.u-s-m-x-60 {
  margin: 0 60px; }

.u-s-m-y-60 {
  margin: 60px 0; }

.u-s-m-xy-60 {
  margin: 60px; }

/* Padding with value `60`. */
.u-s-p-t-60 {
  padding-top: 60px; }

.u-s-p-r-60 {
  padding-right: 60px; }

.u-s-p-b-60 {
  padding-bottom: 60px; }

.u-s-p-l-60 {
  padding-left: 60px; }

.u-s-p-x-60 {
  padding: 0 60px; }

.u-s-p-y-60 {
  padding: 60px 0; }

.u-s-p-xy-60 {
  padding: 60px; }

/* Margin with value `61`. */
.u-s-m-t-61 {
  margin-top: 61px; }

.u-s-m-r-61 {
  margin-right: 61px; }

.u-s-m-b-61 {
  margin-bottom: 61px; }

.u-s-m-l-61 {
  margin-left: 61px; }

.u-s-m-x-61 {
  margin: 0 61px; }

.u-s-m-y-61 {
  margin: 61px 0; }

.u-s-m-xy-61 {
  margin: 61px; }

/* Padding with value `61`. */
.u-s-p-t-61 {
  padding-top: 61px; }

.u-s-p-r-61 {
  padding-right: 61px; }

.u-s-p-b-61 {
  padding-bottom: 61px; }

.u-s-p-l-61 {
  padding-left: 61px; }

.u-s-p-x-61 {
  padding: 0 61px; }

.u-s-p-y-61 {
  padding: 61px 0; }

.u-s-p-xy-61 {
  padding: 61px; }

/* Margin with value `62`. */
.u-s-m-t-62 {
  margin-top: 62px; }

.u-s-m-r-62 {
  margin-right: 62px; }

.u-s-m-b-62 {
  margin-bottom: 62px; }

.u-s-m-l-62 {
  margin-left: 62px; }

.u-s-m-x-62 {
  margin: 0 62px; }

.u-s-m-y-62 {
  margin: 62px 0; }

.u-s-m-xy-62 {
  margin: 62px; }

/* Padding with value `62`. */
.u-s-p-t-62 {
  padding-top: 62px; }

.u-s-p-r-62 {
  padding-right: 62px; }

.u-s-p-b-62 {
  padding-bottom: 62px; }

.u-s-p-l-62 {
  padding-left: 62px; }

.u-s-p-x-62 {
  padding: 0 62px; }

.u-s-p-y-62 {
  padding: 62px 0; }

.u-s-p-xy-62 {
  padding: 62px; }

/* Margin with value `63`. */
.u-s-m-t-63 {
  margin-top: 63px; }

.u-s-m-r-63 {
  margin-right: 63px; }

.u-s-m-b-63 {
  margin-bottom: 63px; }

.u-s-m-l-63 {
  margin-left: 63px; }

.u-s-m-x-63 {
  margin: 0 63px; }

.u-s-m-y-63 {
  margin: 63px 0; }

.u-s-m-xy-63 {
  margin: 63px; }

/* Padding with value `63`. */
.u-s-p-t-63 {
  padding-top: 63px; }

.u-s-p-r-63 {
  padding-right: 63px; }

.u-s-p-b-63 {
  padding-bottom: 63px; }

.u-s-p-l-63 {
  padding-left: 63px; }

.u-s-p-x-63 {
  padding: 0 63px; }

.u-s-p-y-63 {
  padding: 63px 0; }

.u-s-p-xy-63 {
  padding: 63px; }

/* Margin with value `64`. */
.u-s-m-t-64 {
  margin-top: 64px; }

.u-s-m-r-64 {
  margin-right: 64px; }

.u-s-m-b-64 {
  margin-bottom: 64px; }

.u-s-m-l-64 {
  margin-left: 64px; }

.u-s-m-x-64 {
  margin: 0 64px; }

.u-s-m-y-64 {
  margin: 64px 0; }

.u-s-m-xy-64 {
  margin: 64px; }

/* Padding with value `64`. */
.u-s-p-t-64 {
  padding-top: 64px; }

.u-s-p-r-64 {
  padding-right: 64px; }

.u-s-p-b-64 {
  padding-bottom: 64px; }

.u-s-p-l-64 {
  padding-left: 64px; }

.u-s-p-x-64 {
  padding: 0 64px; }

.u-s-p-y-64 {
  padding: 64px 0; }

.u-s-p-xy-64 {
  padding: 64px; }

/* Margin with value `65`. */
.u-s-m-t-65 {
  margin-top: 65px; }

.u-s-m-r-65 {
  margin-right: 65px; }

.u-s-m-b-65 {
  margin-bottom: 65px; }

.u-s-m-l-65 {
  margin-left: 65px; }

.u-s-m-x-65 {
  margin: 0 65px; }

.u-s-m-y-65 {
  margin: 65px 0; }

.u-s-m-xy-65 {
  margin: 65px; }

/* Padding with value `65`. */
.u-s-p-t-65 {
  padding-top: 65px; }

.u-s-p-r-65 {
  padding-right: 65px; }

.u-s-p-b-65 {
  padding-bottom: 65px; }

.u-s-p-l-65 {
  padding-left: 65px; }

.u-s-p-x-65 {
  padding: 0 65px; }

.u-s-p-y-65 {
  padding: 65px 0; }

.u-s-p-xy-65 {
  padding: 65px; }

/* Margin with value `66`. */
.u-s-m-t-66 {
  margin-top: 66px; }

.u-s-m-r-66 {
  margin-right: 66px; }

.u-s-m-b-66 {
  margin-bottom: 66px; }

.u-s-m-l-66 {
  margin-left: 66px; }

.u-s-m-x-66 {
  margin: 0 66px; }

.u-s-m-y-66 {
  margin: 66px 0; }

.u-s-m-xy-66 {
  margin: 66px; }

/* Padding with value `66`. */
.u-s-p-t-66 {
  padding-top: 66px; }

.u-s-p-r-66 {
  padding-right: 66px; }

.u-s-p-b-66 {
  padding-bottom: 66px; }

.u-s-p-l-66 {
  padding-left: 66px; }

.u-s-p-x-66 {
  padding: 0 66px; }

.u-s-p-y-66 {
  padding: 66px 0; }

.u-s-p-xy-66 {
  padding: 66px; }

/* Margin with value `67`. */
.u-s-m-t-67 {
  margin-top: 67px; }

.u-s-m-r-67 {
  margin-right: 67px; }

.u-s-m-b-67 {
  margin-bottom: 67px; }

.u-s-m-l-67 {
  margin-left: 67px; }

.u-s-m-x-67 {
  margin: 0 67px; }

.u-s-m-y-67 {
  margin: 67px 0; }

.u-s-m-xy-67 {
  margin: 67px; }

/* Padding with value `67`. */
.u-s-p-t-67 {
  padding-top: 67px; }

.u-s-p-r-67 {
  padding-right: 67px; }

.u-s-p-b-67 {
  padding-bottom: 67px; }

.u-s-p-l-67 {
  padding-left: 67px; }

.u-s-p-x-67 {
  padding: 0 67px; }

.u-s-p-y-67 {
  padding: 67px 0; }

.u-s-p-xy-67 {
  padding: 67px; }

/* Margin with value `68`. */
.u-s-m-t-68 {
  margin-top: 68px; }

.u-s-m-r-68 {
  margin-right: 68px; }

.u-s-m-b-68 {
  margin-bottom: 68px; }

.u-s-m-l-68 {
  margin-left: 68px; }

.u-s-m-x-68 {
  margin: 0 68px; }

.u-s-m-y-68 {
  margin: 68px 0; }

.u-s-m-xy-68 {
  margin: 68px; }

/* Padding with value `68`. */
.u-s-p-t-68 {
  padding-top: 68px; }

.u-s-p-r-68 {
  padding-right: 68px; }

.u-s-p-b-68 {
  padding-bottom: 68px; }

.u-s-p-l-68 {
  padding-left: 68px; }

.u-s-p-x-68 {
  padding: 0 68px; }

.u-s-p-y-68 {
  padding: 68px 0; }

.u-s-p-xy-68 {
  padding: 68px; }

/* Margin with value `69`. */
.u-s-m-t-69 {
  margin-top: 69px; }

.u-s-m-r-69 {
  margin-right: 69px; }

.u-s-m-b-69 {
  margin-bottom: 69px; }

.u-s-m-l-69 {
  margin-left: 69px; }

.u-s-m-x-69 {
  margin: 0 69px; }

.u-s-m-y-69 {
  margin: 69px 0; }

.u-s-m-xy-69 {
  margin: 69px; }

/* Padding with value `69`. */
.u-s-p-t-69 {
  padding-top: 69px; }

.u-s-p-r-69 {
  padding-right: 69px; }

.u-s-p-b-69 {
  padding-bottom: 69px; }

.u-s-p-l-69 {
  padding-left: 69px; }

.u-s-p-x-69 {
  padding: 0 69px; }

.u-s-p-y-69 {
  padding: 69px 0; }

.u-s-p-xy-69 {
  padding: 69px; }

/* Margin with value `70`. */
.u-s-m-t-70 {
  margin-top: 70px; }

.u-s-m-r-70 {
  margin-right: 70px; }

.u-s-m-b-70 {
  margin-bottom: 70px; }

.u-s-m-l-70 {
  margin-left: 70px; }

.u-s-m-x-70 {
  margin: 0 70px; }

.u-s-m-y-70 {
  margin: 70px 0; }

.u-s-m-xy-70 {
  margin: 70px; }

/* Padding with value `70`. */
.u-s-p-t-70 {
  padding-top: 70px; }

.u-s-p-r-70 {
  padding-right: 70px; }

.u-s-p-b-70 {
  padding-bottom: 70px; }

.u-s-p-l-70 {
  padding-left: 70px; }

.u-s-p-x-70 {
  padding: 0 70px; }

.u-s-p-y-70 {
  padding: 70px 0; }

.u-s-p-xy-70 {
  padding: 70px; }

/* Margin with value `71`. */
.u-s-m-t-71 {
  margin-top: 71px; }

.u-s-m-r-71 {
  margin-right: 71px; }

.u-s-m-b-71 {
  margin-bottom: 71px; }

.u-s-m-l-71 {
  margin-left: 71px; }

.u-s-m-x-71 {
  margin: 0 71px; }

.u-s-m-y-71 {
  margin: 71px 0; }

.u-s-m-xy-71 {
  margin: 71px; }

/* Padding with value `71`. */
.u-s-p-t-71 {
  padding-top: 71px; }

.u-s-p-r-71 {
  padding-right: 71px; }

.u-s-p-b-71 {
  padding-bottom: 71px; }

.u-s-p-l-71 {
  padding-left: 71px; }

.u-s-p-x-71 {
  padding: 0 71px; }

.u-s-p-y-71 {
  padding: 71px 0; }

.u-s-p-xy-71 {
  padding: 71px; }

/* Margin with value `72`. */
.u-s-m-t-72 {
  margin-top: 72px; }

.u-s-m-r-72 {
  margin-right: 72px; }

.u-s-m-b-72 {
  margin-bottom: 72px; }

.u-s-m-l-72 {
  margin-left: 72px; }

.u-s-m-x-72 {
  margin: 0 72px; }

.u-s-m-y-72 {
  margin: 72px 0; }

.u-s-m-xy-72 {
  margin: 72px; }

/* Padding with value `72`. */
.u-s-p-t-72 {
  padding-top: 72px; }

.u-s-p-r-72 {
  padding-right: 72px; }

.u-s-p-b-72 {
  padding-bottom: 72px; }

.u-s-p-l-72 {
  padding-left: 72px; }

.u-s-p-x-72 {
  padding: 0 72px; }

.u-s-p-y-72 {
  padding: 72px 0; }

.u-s-p-xy-72 {
  padding: 72px; }

/* Margin with value `73`. */
.u-s-m-t-73 {
  margin-top: 73px; }

.u-s-m-r-73 {
  margin-right: 73px; }

.u-s-m-b-73 {
  margin-bottom: 73px; }

.u-s-m-l-73 {
  margin-left: 73px; }

.u-s-m-x-73 {
  margin: 0 73px; }

.u-s-m-y-73 {
  margin: 73px 0; }

.u-s-m-xy-73 {
  margin: 73px; }

/* Padding with value `73`. */
.u-s-p-t-73 {
  padding-top: 73px; }

.u-s-p-r-73 {
  padding-right: 73px; }

.u-s-p-b-73 {
  padding-bottom: 73px; }

.u-s-p-l-73 {
  padding-left: 73px; }

.u-s-p-x-73 {
  padding: 0 73px; }

.u-s-p-y-73 {
  padding: 73px 0; }

.u-s-p-xy-73 {
  padding: 73px; }

/* Margin with value `74`. */
.u-s-m-t-74 {
  margin-top: 74px; }

.u-s-m-r-74 {
  margin-right: 74px; }

.u-s-m-b-74 {
  margin-bottom: 74px; }

.u-s-m-l-74 {
  margin-left: 74px; }

.u-s-m-x-74 {
  margin: 0 74px; }

.u-s-m-y-74 {
  margin: 74px 0; }

.u-s-m-xy-74 {
  margin: 74px; }

/* Padding with value `74`. */
.u-s-p-t-74 {
  padding-top: 74px; }

.u-s-p-r-74 {
  padding-right: 74px; }

.u-s-p-b-74 {
  padding-bottom: 74px; }

.u-s-p-l-74 {
  padding-left: 74px; }

.u-s-p-x-74 {
  padding: 0 74px; }

.u-s-p-y-74 {
  padding: 74px 0; }

.u-s-p-xy-74 {
  padding: 74px; }

/* Margin with value `75`. */
.u-s-m-t-75 {
  margin-top: 75px; }

.u-s-m-r-75 {
  margin-right: 75px; }

.u-s-m-b-75 {
  margin-bottom: 75px; }

.u-s-m-l-75 {
  margin-left: 75px; }

.u-s-m-x-75 {
  margin: 0 75px; }

.u-s-m-y-75 {
  margin: 75px 0; }

.u-s-m-xy-75 {
  margin: 75px; }

/* Padding with value `75`. */
.u-s-p-t-75 {
  padding-top: 75px; }

.u-s-p-r-75 {
  padding-right: 75px; }

.u-s-p-b-75 {
  padding-bottom: 75px; }

.u-s-p-l-75 {
  padding-left: 75px; }

.u-s-p-x-75 {
  padding: 0 75px; }

.u-s-p-y-75 {
  padding: 75px 0; }

.u-s-p-xy-75 {
  padding: 75px; }

/* Margin with value `76`. */
.u-s-m-t-76 {
  margin-top: 76px; }

.u-s-m-r-76 {
  margin-right: 76px; }

.u-s-m-b-76 {
  margin-bottom: 76px; }

.u-s-m-l-76 {
  margin-left: 76px; }

.u-s-m-x-76 {
  margin: 0 76px; }

.u-s-m-y-76 {
  margin: 76px 0; }

.u-s-m-xy-76 {
  margin: 76px; }

/* Padding with value `76`. */
.u-s-p-t-76 {
  padding-top: 76px; }

.u-s-p-r-76 {
  padding-right: 76px; }

.u-s-p-b-76 {
  padding-bottom: 76px; }

.u-s-p-l-76 {
  padding-left: 76px; }

.u-s-p-x-76 {
  padding: 0 76px; }

.u-s-p-y-76 {
  padding: 76px 0; }

.u-s-p-xy-76 {
  padding: 76px; }

/* Margin with value `77`. */
.u-s-m-t-77 {
  margin-top: 77px; }

.u-s-m-r-77 {
  margin-right: 77px; }

.u-s-m-b-77 {
  margin-bottom: 77px; }

.u-s-m-l-77 {
  margin-left: 77px; }

.u-s-m-x-77 {
  margin: 0 77px; }

.u-s-m-y-77 {
  margin: 77px 0; }

.u-s-m-xy-77 {
  margin: 77px; }

/* Padding with value `77`. */
.u-s-p-t-77 {
  padding-top: 77px; }

.u-s-p-r-77 {
  padding-right: 77px; }

.u-s-p-b-77 {
  padding-bottom: 77px; }

.u-s-p-l-77 {
  padding-left: 77px; }

.u-s-p-x-77 {
  padding: 0 77px; }

.u-s-p-y-77 {
  padding: 77px 0; }

.u-s-p-xy-77 {
  padding: 77px; }

/* Margin with value `78`. */
.u-s-m-t-78 {
  margin-top: 78px; }

.u-s-m-r-78 {
  margin-right: 78px; }

.u-s-m-b-78 {
  margin-bottom: 78px; }

.u-s-m-l-78 {
  margin-left: 78px; }

.u-s-m-x-78 {
  margin: 0 78px; }

.u-s-m-y-78 {
  margin: 78px 0; }

.u-s-m-xy-78 {
  margin: 78px; }

/* Padding with value `78`. */
.u-s-p-t-78 {
  padding-top: 78px; }

.u-s-p-r-78 {
  padding-right: 78px; }

.u-s-p-b-78 {
  padding-bottom: 78px; }

.u-s-p-l-78 {
  padding-left: 78px; }

.u-s-p-x-78 {
  padding: 0 78px; }

.u-s-p-y-78 {
  padding: 78px 0; }

.u-s-p-xy-78 {
  padding: 78px; }

/* Margin with value `79`. */
.u-s-m-t-79 {
  margin-top: 79px; }

.u-s-m-r-79 {
  margin-right: 79px; }

.u-s-m-b-79 {
  margin-bottom: 79px; }

.u-s-m-l-79 {
  margin-left: 79px; }

.u-s-m-x-79 {
  margin: 0 79px; }

.u-s-m-y-79 {
  margin: 79px 0; }

.u-s-m-xy-79 {
  margin: 79px; }

/* Padding with value `79`. */
.u-s-p-t-79 {
  padding-top: 79px; }

.u-s-p-r-79 {
  padding-right: 79px; }

.u-s-p-b-79 {
  padding-bottom: 79px; }

.u-s-p-l-79 {
  padding-left: 79px; }

.u-s-p-x-79 {
  padding: 0 79px; }

.u-s-p-y-79 {
  padding: 79px 0; }

.u-s-p-xy-79 {
  padding: 79px; }

/* Margin with value `80`. */
.u-s-m-t-80 {
  margin-top: 80px; }

.u-s-m-r-80 {
  margin-right: 80px; }

.u-s-m-b-80 {
  margin-bottom: 80px; }

.u-s-m-l-80 {
  margin-left: 80px; }

.u-s-m-x-80 {
  margin: 0 80px; }

.u-s-m-y-80 {
  margin: 80px 0; }

.u-s-m-xy-80 {
  margin: 80px; }

/* Padding with value `80`. */
.u-s-p-t-80 {
  padding-top: 80px; }

.u-s-p-r-80 {
  padding-right: 80px; }

.u-s-p-b-80 {
  padding-bottom: 80px; }

.u-s-p-l-80 {
  padding-left: 80px; }

.u-s-p-x-80 {
  padding: 0 80px; }

.u-s-p-y-80 {
  padding: 80px 0; }

.u-s-p-xy-80 {
  padding: 80px; }

/* Margin with value `81`. */
.u-s-m-t-81 {
  margin-top: 81px; }

.u-s-m-r-81 {
  margin-right: 81px; }

.u-s-m-b-81 {
  margin-bottom: 81px; }

.u-s-m-l-81 {
  margin-left: 81px; }

.u-s-m-x-81 {
  margin: 0 81px; }

.u-s-m-y-81 {
  margin: 81px 0; }

.u-s-m-xy-81 {
  margin: 81px; }

/* Padding with value `81`. */
.u-s-p-t-81 {
  padding-top: 81px; }

.u-s-p-r-81 {
  padding-right: 81px; }

.u-s-p-b-81 {
  padding-bottom: 81px; }

.u-s-p-l-81 {
  padding-left: 81px; }

.u-s-p-x-81 {
  padding: 0 81px; }

.u-s-p-y-81 {
  padding: 81px 0; }

.u-s-p-xy-81 {
  padding: 81px; }

/* Margin with value `82`. */
.u-s-m-t-82 {
  margin-top: 82px; }

.u-s-m-r-82 {
  margin-right: 82px; }

.u-s-m-b-82 {
  margin-bottom: 82px; }

.u-s-m-l-82 {
  margin-left: 82px; }

.u-s-m-x-82 {
  margin: 0 82px; }

.u-s-m-y-82 {
  margin: 82px 0; }

.u-s-m-xy-82 {
  margin: 82px; }

/* Padding with value `82`. */
.u-s-p-t-82 {
  padding-top: 82px; }

.u-s-p-r-82 {
  padding-right: 82px; }

.u-s-p-b-82 {
  padding-bottom: 82px; }

.u-s-p-l-82 {
  padding-left: 82px; }

.u-s-p-x-82 {
  padding: 0 82px; }

.u-s-p-y-82 {
  padding: 82px 0; }

.u-s-p-xy-82 {
  padding: 82px; }

/* Margin with value `83`. */
.u-s-m-t-83 {
  margin-top: 83px; }

.u-s-m-r-83 {
  margin-right: 83px; }

.u-s-m-b-83 {
  margin-bottom: 83px; }

.u-s-m-l-83 {
  margin-left: 83px; }

.u-s-m-x-83 {
  margin: 0 83px; }

.u-s-m-y-83 {
  margin: 83px 0; }

.u-s-m-xy-83 {
  margin: 83px; }

/* Padding with value `83`. */
.u-s-p-t-83 {
  padding-top: 83px; }

.u-s-p-r-83 {
  padding-right: 83px; }

.u-s-p-b-83 {
  padding-bottom: 83px; }

.u-s-p-l-83 {
  padding-left: 83px; }

.u-s-p-x-83 {
  padding: 0 83px; }

.u-s-p-y-83 {
  padding: 83px 0; }

.u-s-p-xy-83 {
  padding: 83px; }

/* Margin with value `84`. */
.u-s-m-t-84 {
  margin-top: 84px; }

.u-s-m-r-84 {
  margin-right: 84px; }

.u-s-m-b-84 {
  margin-bottom: 84px; }

.u-s-m-l-84 {
  margin-left: 84px; }

.u-s-m-x-84 {
  margin: 0 84px; }

.u-s-m-y-84 {
  margin: 84px 0; }

.u-s-m-xy-84 {
  margin: 84px; }

/* Padding with value `84`. */
.u-s-p-t-84 {
  padding-top: 84px; }

.u-s-p-r-84 {
  padding-right: 84px; }

.u-s-p-b-84 {
  padding-bottom: 84px; }

.u-s-p-l-84 {
  padding-left: 84px; }

.u-s-p-x-84 {
  padding: 0 84px; }

.u-s-p-y-84 {
  padding: 84px 0; }

.u-s-p-xy-84 {
  padding: 84px; }

/* Margin with value `85`. */
.u-s-m-t-85 {
  margin-top: 85px; }

.u-s-m-r-85 {
  margin-right: 85px; }

.u-s-m-b-85 {
  margin-bottom: 85px; }

.u-s-m-l-85 {
  margin-left: 85px; }

.u-s-m-x-85 {
  margin: 0 85px; }

.u-s-m-y-85 {
  margin: 85px 0; }

.u-s-m-xy-85 {
  margin: 85px; }

/* Padding with value `85`. */
.u-s-p-t-85 {
  padding-top: 85px; }

.u-s-p-r-85 {
  padding-right: 85px; }

.u-s-p-b-85 {
  padding-bottom: 85px; }

.u-s-p-l-85 {
  padding-left: 85px; }

.u-s-p-x-85 {
  padding: 0 85px; }

.u-s-p-y-85 {
  padding: 85px 0; }

.u-s-p-xy-85 {
  padding: 85px; }

/* Margin with value `86`. */
.u-s-m-t-86 {
  margin-top: 86px; }

.u-s-m-r-86 {
  margin-right: 86px; }

.u-s-m-b-86 {
  margin-bottom: 86px; }

.u-s-m-l-86 {
  margin-left: 86px; }

.u-s-m-x-86 {
  margin: 0 86px; }

.u-s-m-y-86 {
  margin: 86px 0; }

.u-s-m-xy-86 {
  margin: 86px; }

/* Padding with value `86`. */
.u-s-p-t-86 {
  padding-top: 86px; }

.u-s-p-r-86 {
  padding-right: 86px; }

.u-s-p-b-86 {
  padding-bottom: 86px; }

.u-s-p-l-86 {
  padding-left: 86px; }

.u-s-p-x-86 {
  padding: 0 86px; }

.u-s-p-y-86 {
  padding: 86px 0; }

.u-s-p-xy-86 {
  padding: 86px; }

/* Margin with value `87`. */
.u-s-m-t-87 {
  margin-top: 87px; }

.u-s-m-r-87 {
  margin-right: 87px; }

.u-s-m-b-87 {
  margin-bottom: 87px; }

.u-s-m-l-87 {
  margin-left: 87px; }

.u-s-m-x-87 {
  margin: 0 87px; }

.u-s-m-y-87 {
  margin: 87px 0; }

.u-s-m-xy-87 {
  margin: 87px; }

/* Padding with value `87`. */
.u-s-p-t-87 {
  padding-top: 87px; }

.u-s-p-r-87 {
  padding-right: 87px; }

.u-s-p-b-87 {
  padding-bottom: 87px; }

.u-s-p-l-87 {
  padding-left: 87px; }

.u-s-p-x-87 {
  padding: 0 87px; }

.u-s-p-y-87 {
  padding: 87px 0; }

.u-s-p-xy-87 {
  padding: 87px; }

/* Margin with value `88`. */
.u-s-m-t-88 {
  margin-top: 88px; }

.u-s-m-r-88 {
  margin-right: 88px; }

.u-s-m-b-88 {
  margin-bottom: 88px; }

.u-s-m-l-88 {
  margin-left: 88px; }

.u-s-m-x-88 {
  margin: 0 88px; }

.u-s-m-y-88 {
  margin: 88px 0; }

.u-s-m-xy-88 {
  margin: 88px; }

/* Padding with value `88`. */
.u-s-p-t-88 {
  padding-top: 88px; }

.u-s-p-r-88 {
  padding-right: 88px; }

.u-s-p-b-88 {
  padding-bottom: 88px; }

.u-s-p-l-88 {
  padding-left: 88px; }

.u-s-p-x-88 {
  padding: 0 88px; }

.u-s-p-y-88 {
  padding: 88px 0; }

.u-s-p-xy-88 {
  padding: 88px; }

/* Margin with value `89`. */
.u-s-m-t-89 {
  margin-top: 89px; }

.u-s-m-r-89 {
  margin-right: 89px; }

.u-s-m-b-89 {
  margin-bottom: 89px; }

.u-s-m-l-89 {
  margin-left: 89px; }

.u-s-m-x-89 {
  margin: 0 89px; }

.u-s-m-y-89 {
  margin: 89px 0; }

.u-s-m-xy-89 {
  margin: 89px; }

/* Padding with value `89`. */
.u-s-p-t-89 {
  padding-top: 89px; }

.u-s-p-r-89 {
  padding-right: 89px; }

.u-s-p-b-89 {
  padding-bottom: 89px; }

.u-s-p-l-89 {
  padding-left: 89px; }

.u-s-p-x-89 {
  padding: 0 89px; }

.u-s-p-y-89 {
  padding: 89px 0; }

.u-s-p-xy-89 {
  padding: 89px; }

/* Margin with value `90`. */
.u-s-m-t-90 {
  margin-top: 90px; }

.u-s-m-r-90 {
  margin-right: 90px; }

.u-s-m-b-90 {
  margin-bottom: 90px; }

.u-s-m-l-90 {
  margin-left: 90px; }

.u-s-m-x-90 {
  margin: 0 90px; }

.u-s-m-y-90 {
  margin: 90px 0; }

.u-s-m-xy-90 {
  margin: 90px; }

/* Padding with value `90`. */
.u-s-p-t-90 {
  padding-top: 90px; }

.u-s-p-r-90 {
  padding-right: 90px; }

.u-s-p-b-90 {
  padding-bottom: 90px; }

.u-s-p-l-90 {
  padding-left: 90px; }

.u-s-p-x-90 {
  padding: 0 90px; }

.u-s-p-y-90 {
  padding: 90px 0; }

.u-s-p-xy-90 {
  padding: 90px; }

/* Margin with value `91`. */
.u-s-m-t-91 {
  margin-top: 91px; }

.u-s-m-r-91 {
  margin-right: 91px; }

.u-s-m-b-91 {
  margin-bottom: 91px; }

.u-s-m-l-91 {
  margin-left: 91px; }

.u-s-m-x-91 {
  margin: 0 91px; }

.u-s-m-y-91 {
  margin: 91px 0; }

.u-s-m-xy-91 {
  margin: 91px; }

/* Padding with value `91`. */
.u-s-p-t-91 {
  padding-top: 91px; }

.u-s-p-r-91 {
  padding-right: 91px; }

.u-s-p-b-91 {
  padding-bottom: 91px; }

.u-s-p-l-91 {
  padding-left: 91px; }

.u-s-p-x-91 {
  padding: 0 91px; }

.u-s-p-y-91 {
  padding: 91px 0; }

.u-s-p-xy-91 {
  padding: 91px; }

/* Margin with value `92`. */
.u-s-m-t-92 {
  margin-top: 92px; }

.u-s-m-r-92 {
  margin-right: 92px; }

.u-s-m-b-92 {
  margin-bottom: 92px; }

.u-s-m-l-92 {
  margin-left: 92px; }

.u-s-m-x-92 {
  margin: 0 92px; }

.u-s-m-y-92 {
  margin: 92px 0; }

.u-s-m-xy-92 {
  margin: 92px; }

/* Padding with value `92`. */
.u-s-p-t-92 {
  padding-top: 92px; }

.u-s-p-r-92 {
  padding-right: 92px; }

.u-s-p-b-92 {
  padding-bottom: 92px; }

.u-s-p-l-92 {
  padding-left: 92px; }

.u-s-p-x-92 {
  padding: 0 92px; }

.u-s-p-y-92 {
  padding: 92px 0; }

.u-s-p-xy-92 {
  padding: 92px; }

/* Margin with value `93`. */
.u-s-m-t-93 {
  margin-top: 93px; }

.u-s-m-r-93 {
  margin-right: 93px; }

.u-s-m-b-93 {
  margin-bottom: 93px; }

.u-s-m-l-93 {
  margin-left: 93px; }

.u-s-m-x-93 {
  margin: 0 93px; }

.u-s-m-y-93 {
  margin: 93px 0; }

.u-s-m-xy-93 {
  margin: 93px; }

/* Padding with value `93`. */
.u-s-p-t-93 {
  padding-top: 93px; }

.u-s-p-r-93 {
  padding-right: 93px; }

.u-s-p-b-93 {
  padding-bottom: 93px; }

.u-s-p-l-93 {
  padding-left: 93px; }

.u-s-p-x-93 {
  padding: 0 93px; }

.u-s-p-y-93 {
  padding: 93px 0; }

.u-s-p-xy-93 {
  padding: 93px; }

/* Margin with value `94`. */
.u-s-m-t-94 {
  margin-top: 94px; }

.u-s-m-r-94 {
  margin-right: 94px; }

.u-s-m-b-94 {
  margin-bottom: 94px; }

.u-s-m-l-94 {
  margin-left: 94px; }

.u-s-m-x-94 {
  margin: 0 94px; }

.u-s-m-y-94 {
  margin: 94px 0; }

.u-s-m-xy-94 {
  margin: 94px; }

/* Padding with value `94`. */
.u-s-p-t-94 {
  padding-top: 94px; }

.u-s-p-r-94 {
  padding-right: 94px; }

.u-s-p-b-94 {
  padding-bottom: 94px; }

.u-s-p-l-94 {
  padding-left: 94px; }

.u-s-p-x-94 {
  padding: 0 94px; }

.u-s-p-y-94 {
  padding: 94px 0; }

.u-s-p-xy-94 {
  padding: 94px; }

/* Margin with value `95`. */
.u-s-m-t-95 {
  margin-top: 95px; }

.u-s-m-r-95 {
  margin-right: 95px; }

.u-s-m-b-95 {
  margin-bottom: 95px; }

.u-s-m-l-95 {
  margin-left: 95px; }

.u-s-m-x-95 {
  margin: 0 95px; }

.u-s-m-y-95 {
  margin: 95px 0; }

.u-s-m-xy-95 {
  margin: 95px; }

/* Padding with value `95`. */
.u-s-p-t-95 {
  padding-top: 95px; }

.u-s-p-r-95 {
  padding-right: 95px; }

.u-s-p-b-95 {
  padding-bottom: 95px; }

.u-s-p-l-95 {
  padding-left: 95px; }

.u-s-p-x-95 {
  padding: 0 95px; }

.u-s-p-y-95 {
  padding: 95px 0; }

.u-s-p-xy-95 {
  padding: 95px; }

/* Margin with value `96`. */
.u-s-m-t-96 {
  margin-top: 96px; }

.u-s-m-r-96 {
  margin-right: 96px; }

.u-s-m-b-96 {
  margin-bottom: 96px; }

.u-s-m-l-96 {
  margin-left: 96px; }

.u-s-m-x-96 {
  margin: 0 96px; }

.u-s-m-y-96 {
  margin: 96px 0; }

.u-s-m-xy-96 {
  margin: 96px; }

/* Padding with value `96`. */
.u-s-p-t-96 {
  padding-top: 96px; }

.u-s-p-r-96 {
  padding-right: 96px; }

.u-s-p-b-96 {
  padding-bottom: 96px; }

.u-s-p-l-96 {
  padding-left: 96px; }

.u-s-p-x-96 {
  padding: 0 96px; }

.u-s-p-y-96 {
  padding: 96px 0; }

.u-s-p-xy-96 {
  padding: 96px; }

/* Margin with value `97`. */
.u-s-m-t-97 {
  margin-top: 97px; }

.u-s-m-r-97 {
  margin-right: 97px; }

.u-s-m-b-97 {
  margin-bottom: 97px; }

.u-s-m-l-97 {
  margin-left: 97px; }

.u-s-m-x-97 {
  margin: 0 97px; }

.u-s-m-y-97 {
  margin: 97px 0; }

.u-s-m-xy-97 {
  margin: 97px; }

/* Padding with value `97`. */
.u-s-p-t-97 {
  padding-top: 97px; }

.u-s-p-r-97 {
  padding-right: 97px; }

.u-s-p-b-97 {
  padding-bottom: 97px; }

.u-s-p-l-97 {
  padding-left: 97px; }

.u-s-p-x-97 {
  padding: 0 97px; }

.u-s-p-y-97 {
  padding: 97px 0; }

.u-s-p-xy-97 {
  padding: 97px; }

/* Margin with value `98`. */
.u-s-m-t-98 {
  margin-top: 98px; }

.u-s-m-r-98 {
  margin-right: 98px; }

.u-s-m-b-98 {
  margin-bottom: 98px; }

.u-s-m-l-98 {
  margin-left: 98px; }

.u-s-m-x-98 {
  margin: 0 98px; }

.u-s-m-y-98 {
  margin: 98px 0; }

.u-s-m-xy-98 {
  margin: 98px; }

/* Padding with value `98`. */
.u-s-p-t-98 {
  padding-top: 98px; }

.u-s-p-r-98 {
  padding-right: 98px; }

.u-s-p-b-98 {
  padding-bottom: 98px; }

.u-s-p-l-98 {
  padding-left: 98px; }

.u-s-p-x-98 {
  padding: 0 98px; }

.u-s-p-y-98 {
  padding: 98px 0; }

.u-s-p-xy-98 {
  padding: 98px; }

/* Margin with value `99`. */
.u-s-m-t-99 {
  margin-top: 99px; }

.u-s-m-r-99 {
  margin-right: 99px; }

.u-s-m-b-99 {
  margin-bottom: 99px; }

.u-s-m-l-99 {
  margin-left: 99px; }

.u-s-m-x-99 {
  margin: 0 99px; }

.u-s-m-y-99 {
  margin: 99px 0; }

.u-s-m-xy-99 {
  margin: 99px; }

/* Padding with value `99`. */
.u-s-p-t-99 {
  padding-top: 99px; }

.u-s-p-r-99 {
  padding-right: 99px; }

.u-s-p-b-99 {
  padding-bottom: 99px; }

.u-s-p-l-99 {
  padding-left: 99px; }

.u-s-p-x-99 {
  padding: 0 99px; }

.u-s-p-y-99 {
  padding: 99px 0; }

.u-s-p-xy-99 {
  padding: 99px; }

/* Margin with value `100`. */
.u-s-m-t-100 {
  margin-top: 100px; }

.u-s-m-r-100 {
  margin-right: 100px; }

.u-s-m-b-100 {
  margin-bottom: 100px; }

.u-s-m-l-100 {
  margin-left: 100px; }

.u-s-m-x-100 {
  margin: 0 100px; }

.u-s-m-y-100 {
  margin: 100px 0; }

.u-s-m-xy-100 {
  margin: 100px; }

/* Padding with value `100`. */
.u-s-p-t-100 {
  padding-top: 100px; }

.u-s-p-r-100 {
  padding-right: 100px; }

.u-s-p-b-100 {
  padding-bottom: 100px; }

.u-s-p-l-100 {
  padding-left: 100px; }

.u-s-p-x-100 {
  padding: 0 100px; }

.u-s-p-y-100 {
  padding: 100px 0; }

.u-s-p-xy-100 {
  padding: 100px; }

/**
 * Utility classes which contain break points:
*/
/* Large devices (desktops, less than and equal to 1199px) */
@media (max-width: 1199px) {
  /* Display utility class */
  .u-d-none-xlg {
    display: none !important; }
  /* Text utility class */
  .u-t-center-xlg {
    text-align: center !important; } }

/* Medium devices (tablets, less than and equal to 991px) */
@media (max-width: 991px) {
  /* Display utility class */
  .u-d-none-lg {
    display: none !important; }
  /* Text utility class */
  .u-t-center-lg {
    text-align: center !important; } }

/* Small devices (landscape phones, less than and equal to 767px) */
@media (max-width: 767px) {
  /* Display utility class */
  .u-d-none-md {
    display: none !important; }
  /* Text utility class */
  .u-t-center-md {
    text-align: center !important; } }

/* Extra small devices (portrait phones, less than and equal to 575px) */
@media (max-width: 575px) {
  /* Display utility class */
  .u-d-none-sm {
    display: none !important; }
  /* Text utility class */
  .u-t-center-sm {
    text-align: center !important; } }

